import avatar from './avatar.png';

import './fonts.css';
import './normalize.css';
import './skeleton-auto.css';
import './brands.css';

import twitter from './twitter.svg';
import instagram from './instagram.svg';
import youtube from './youtube.svg';
import discord from './discord.svg';
import twitch from './twitch.svg';

function App() {
  return (
    <div className="container">
      <div className="container">
        <div className="row">
          <div className="column" style={{marginTop: '12%'}}>
            <img src={avatar} className="avatar" alt="Avatar" />
            
            <h1>TapyAqua</h1>
            
            <p>Der absolut unlustigste Streamer auf Twitch. <br /> Aber irgendwie schauen mir trotzdem Leute zu.</p>
            
            <a className="button button-twitter" href="https://www.tapyaqua.de/twitter" target="_blank" rel="noopener noreferrer">
              <img src={twitter} className="icon" alt="Twitter Logo" />Twitter
            </a>
            
            <a className="button button-instagram" href="https://www.tapyaqua.de/instagram" target="_blank" rel="noopener noreferrer">
              <img src={instagram} className="icon" alt="Instagram Logo" />Instagram
            </a>
            
            <a className="button button-youtube" href="https://www.tapyaqua.de/youtube" target="_blank" rel="noopener noreferrer">
              <img src={youtube} className="icon" alt="YouTube Logo" />YouTube
            </a>
            
            <a className="button button-discord" href="https://www.tapyaqua.de/discord" target="_blank" rel="noopener noreferrer">
              <img src={discord} className="icon" alt="Discord Logo" />Discord
            </a>
            
            <a className="button button-twitch" href="https://www.tapyaqua.de/twitch" target="_blank" rel="noopener noreferrer">
              <img src={twitch} className="icon" alt="Twitch Logo" />Twitch
            </a>
            
            <p className="footer">&copy; {new Date().getFullYear()} <strong>TapyAqua</strong> – Alle Rechte vorbehalten.
            
              <br />
              
              <br />
              
              <small>Dies ist eine nicht kommerzielle, private Website. Es wird keinerlei Verantwortung gegenüber der verlinkten Inhalte übernommen.</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
